// extracted by mini-css-extract-plugin
export var bgGrey100 = "HeadingContent-module--bg-grey-100--85689";
export var bgGrey150 = "HeadingContent-module--bg-grey-150--90d74";
export var bgGrey200 = "HeadingContent-module--bg-grey-200--0f7aa";
export var bgGrey300 = "HeadingContent-module--bg-grey-300--6ce67";
export var bgGrey400 = "HeadingContent-module--bg-grey-400--49f7f";
export var bgGrey500 = "HeadingContent-module--bg-grey-500--fbd39";
export var bgGrey600 = "HeadingContent-module--bg-grey-600--a1582";
export var bgGrey700 = "HeadingContent-module--bg-grey-700--b03e4";
export var bgGrey800 = "HeadingContent-module--bg-grey-800--c8d96";
export var bgGrey900 = "HeadingContent-module--bg-grey-900--9b9a2";
export var sectionHeadingCls = "HeadingContent-module--sectionHeadingCls--5fc3f";
export var subHeadingCls = "HeadingContent-module--subHeadingCls--fa5f2";
export var textGrey100 = "HeadingContent-module--text-grey-100--6c903";
export var textGrey150 = "HeadingContent-module--text-grey-150--343c5";
export var textGrey200 = "HeadingContent-module--text-grey-200--6cd52";
export var textGrey300 = "HeadingContent-module--text-grey-300--3c206";
export var textGrey400 = "HeadingContent-module--text-grey-400--1265e";
export var textGrey500 = "HeadingContent-module--text-grey-500--f12fb";
export var textGrey600 = "HeadingContent-module--text-grey-600--31c3a";
export var textGrey700 = "HeadingContent-module--text-grey-700--cd9b9";
export var textGrey800 = "HeadingContent-module--text-grey-800--3e26e";
export var textGrey900 = "HeadingContent-module--text-grey-900--11814";