import React from "react"

import { subHeadingCls } from "./HeadingContent.module.scss"
import PaddingWrapper from "./PaddingWrapper"

const HeadingContent = ({ heading, config }) => (
  <PaddingWrapper padding={config?.padding}>
    <h3 className={subHeadingCls}>{heading}</h3>
  </PaddingWrapper>
)

export default HeadingContent
